define("additive-mi/controllers/instance/settings/budget", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-concurrency", "@additive-apps/ui/utils/format-currency", "@additive-apps/ui/utils/query-param-util", "ember-changeset", "lodash.clonedeep"], function (_exports, _controller, _object, _service, _tracking, _emberConcurrency, _formatCurrency, _queryParamUtil, _emberChangeset, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceSettingsBudgets = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    this.isError = false;
    try {
      const adapter = this.store.adapterFor('budget');
      const url = adapter.buildURL('budget');
      const response = yield this.authenticatedFetch.fetch(url);
      if (!response || !response.ok) throw new Error('[BUDGET-SETTINGS] fetch error');
      const {
        budgets
      } = yield response.json();
      const budgetSerializer = this.store.serializerFor('budget');

      // push records into store in order to use model actions
      Object.keys(budgets).forEach(key => {
        if (!budgets[key]) return;
        const budgetGroup = Array.isArray(budgets[key]) ? budgets[key] : [budgets[key]];
        budgets[key] = budgetGroup.map(budget => {
          const peekedBudget = this.store.peekRecord('budget', budget.id);
          if (peekedBudget) return peekedBudget;

          // normalize budget and add it to the store
          const normalizedBudget = budgetSerializer.normalizeResponse(this.store, this.store.modelFor('budget'), {
            budget
          }, budget.id, 'findRecord');
          return this.store.push(normalizedBudget);
        });
      });
      this.budgets = budgets;
      this.checkIfCurrentBudgetDefined();
    } catch (error) {
      this.isError = true;
    }
  }), _dec2 = (0, _emberConcurrency.task)(function* () {
    this.isError = false;
    try {
      const adapter = this.store.adapterFor('budget');
      const url = adapter.buildURL('budget');
      const response = yield this.authenticatedFetch.fetch(`${url}/automated-import`);
      if (!response || !response.ok) throw new Error('[BUDGET-AUTOMATED-IMPORT-SETTINGS] fetch error');
      const json = yield response.json();
      this._automatedImportSettings = new _emberChangeset.default(json);
    } catch (error) {
      this.isError = true;
    }
  }), _dec3 = (0, _emberConcurrency.task)(function* (name) {
    this.isError = false;
    try {
      const adapter = this.store.adapterFor('budget');
      const url = adapter.buildURL('budget');
      const type = this._selectedAdPlatform;
      const filters = {
        type
      };
      if (name) {
        filters['name'] = name;
      }
      const qps = (0, _queryParamUtil.getQueryParams)(filters);
      const response = yield this.authenticatedFetch.fetch(`${url}/automated-import/campaigns${qps}`);
      if (!response || !response.ok) throw new Error('[BUDGET-AUTOMATED-IMPORT-SETTINGS] fetch error');
      const {
        campaigns
      } = yield response.json();
      this._selectableCampaigns = campaigns;
    } catch (error) {
      this.isError = true;
    }
  }), _dec4 = (0, _emberConcurrency.task)(function* () {
    this.isError = false;
    try {
      const adapter = this.store.adapterFor('budget');
      const url = adapter.buildURL('budget');
      const body = {
        active: this._automatedImportSettings.get('active'),
        excludedCampaigns: this._automatedImportSettings.get('excludedCampaigns')
      };
      const response = yield this.authenticatedFetch.fetch(`${url}/automated-import`, {
        method: 'PUT'
      }, body);
      if (!response || !response.ok) throw new Error('[BUDGET-AUTOMATED-IMPORT-SETTINGS] fetch error');
      const json = yield response.json();
      const changes = this._automatedImportSettings.changes;
      const activeChange = changes.find(change => change.key === 'active');

      // Refetch budgets when automated import is activated
      if (activeChange?.value === true) {
        yield this.fetchBudgets.perform();
      }
      this._automatedImportSettings = new _emberChangeset.default(json);
    } catch (error) {
      this.isError = true;
    }
  }), _class = class InstanceSettingsBudgets extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor5, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor6, this);
      _initializerDefineProperty(this, "uiToast", _descriptor7, this);
      _initializerDefineProperty(this, "uiPaths", _descriptor8, this);
      /**
       * the budget settings
       *
       * @property budgets
       * @type {Array}
       * @default null
       */
      _initializerDefineProperty(this, "budgets", _descriptor9, this);
      /**
       * whether an error occured while fetching the budget
       *
       * @property isError
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isError", _descriptor10, this);
      _initializerDefineProperty(this, "_newBudget", _descriptor11, this);
      _initializerDefineProperty(this, "_fabButtonContainer", _descriptor12, this);
      _initializerDefineProperty(this, "_isAutomatedImportSettingsDialog", _descriptor13, this);
      _initializerDefineProperty(this, "_selectedAdPlatform", _descriptor14, this);
      _initializerDefineProperty(this, "_automatedImportSettings", _descriptor15, this);
      _initializerDefineProperty(this, "_isAutomatedImportEdit", _descriptor16, this);
      _initializerDefineProperty(this, "_selectableCampaigns", _descriptor17, this);
      _initializerDefineProperty(this, "_selectedCampaign", _descriptor18, this);
      /**
       * fetches the budget
       *
       * @function fetchBudgets
       * @type {Task}
       */
      _initializerDefineProperty(this, "fetchBudgets", _descriptor19, this);
      /**
       * fetches the settings for the automated-import
       *
       * @function fetchAutomatedImportSettings
       * @type {Task}
       */
      _initializerDefineProperty(this, "fetchAutomatedImportSettings", _descriptor20, this);
      /**
       * fetches the campaigns for the automated-import dialog
       *
       * @function fetchCampaigns
       * @type {Task}
       */
      _initializerDefineProperty(this, "fetchCampaigns", _descriptor21, this);
      /**
       * saves the automated-import settings
       *
       * @function saveAutomatedImportSettings
       * @type {Task}
       */
      _initializerDefineProperty(this, "saveAutomatedImportSettings", _descriptor22, this);
    }
    /**
     * the active budget
     *
     * @property activeBudget
     * @type {Number}
     * @default 0
     */
    get activeBudget() {
      if (this.budgets?.active.length <= 0) {
        return 0;
      }
      const activeBudget = this.budgets?.active[0];
      const meta = activeBudget.meta?.value || 0;
      const googleAds = activeBudget.googleAds?.value || 0;
      const tiktok = activeBudget.tiktok?.value || 0;
      const otherCosts = activeBudget.otherCosts || 0;
      return (0, _formatCurrency.formatCurrency)({
        value: meta + googleAds + tiktok + otherCosts,
        currency: 'EUR',
        locales: this.currentUser?.user?.language,
        divider: 1
      });
    }
    get _readOnly() {
      // if not managed, only managers and admins can edit
      if (!this.uiAppSettings?.managed) {
        return !this.currentUser.isManager;
      }

      // if managed by partner, only additive and partner users can edit
      if (this.uiAppSettings?.managedBy === 'parnter') {
        return !this.currentUser.isAdditiveUser && !this.currentUser.isPartnerUser;
      }

      // if managed by additive, only additive users can edit
      return !this.currentUser.isAdditiveUser;
    }
    get _adPlatforms() {
      return [{
        value: 'meta',
        name: this.intl.t('components.amiSettings.temporalSettingsList.budgets.automatedImport.dialog.meta')
      }, {
        value: 'tiktok',
        name: this.intl.t('components.amiSettings.temporalSettingsList.budgets.automatedImport.dialog.tiktok')
      }, {
        value: 'googleAds',
        name: this.intl.t('components.amiSettings.temporalSettingsList.budgets.automatedImport.dialog.googleAds')
      }];
    }
    setup() {
      this._fabButtonContainer = document.getElementById('settings-fab-button-container');
    }
    openDetailDialog() {
      const startsAt = new Date();
      startsAt.setMonth(startsAt.getMonth() - 1);
      startsAt.setHours(12);
      startsAt.setDate(1);
      this._newBudget = this.store.createRecord('budget', {
        startsAt,
        meta: {
          value: 0
        },
        tiktok: {
          value: 0
        },
        googleAds: {
          value: 0
        }
      });
    }
    async _toggleAutomatedImport(value) {
      this._automatedImportSettings.set('active', value);
      if (value) {
        this._isAutomatedImportSettingsDialog = true;
      } else {
        this.uiDialog.showConfirm(this.intl.t('components.amiSettings.temporalSettingsList.budgets.automatedImport.deactivateDialog.title'), this.intl.t('components.amiSettings.temporalSettingsList.budgets.automatedImport.deactivateDialog.description'), () => {
          this.saveAutomatedImportSettings.perform();
        }, this.intl.t('components.amiSettings.temporalSettingsList.budgets.automatedImport.deactivateDialog.deactivate'));
      }
    }
    _openAutomatedImportDialog(isEdit) {
      this._selectedAdPlatform = 'meta';
      this.fetchCampaigns.perform();
      this._isAutomatedImportSettingsDialog = true;
      if (isEdit) {
        this._isAutomatedImportEdit = true;
      }
    }
    _selectAdPlatform(value) {
      this._selectedAdPlatform = value;
      this.fetchCampaigns.perform();
    }
    _removeExcludedCampaign(campaignId) {
      const filteredCampaigns = this._automatedImportSettings.get('excludedCampaigns').filter(campaign => campaign.id !== campaignId);
      this._automatedImportSettings.set('excludedCampaigns', filteredCampaigns);
    }
    _addExcludedCampaign() {
      if (!this._selectedCampaign) {
        return;
      }
      const excludedCampaigns = (0, _lodash.default)(this._automatedImportSettings.get('excludedCampaigns'));
      const campaign = this._selectableCampaigns.find(campaign => campaign.id === this._selectedCampaign);
      const isAlreadyAdded = excludedCampaigns.some(_campaign => _campaign.id === campaign.id);
      if (!campaign || isAlreadyAdded) {
        return;
      }
      excludedCampaigns.push(campaign);
      this._automatedImportSettings.set('excludedCampaigns', excludedCampaigns);
    }
    async _saveAutomatedImportSettings() {
      await this.saveAutomatedImportSettings.perform();
      this._isAutomatedImportSettingsDialog = false;
      this._isAutomatedImportEdit = false;
    }
    onCloseAutomatedImportSettings() {
      if (!this._automatedImportSettings.get('isDirty')) {
        this._isAutomatedImportSettingsDialog = false;
        this._isAutomatedImportEdit = false;
        return;
      }
      this.uiDialog.showDiscardChangesConfirm(() => {
        this._automatedImportSettings.rollback();
        this._isAutomatedImportSettingsDialog = false;
        this._isAutomatedImportEdit = false;
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiPaths", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "budgets", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_newBudget", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_fabButtonContainer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "_isAutomatedImportSettingsDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "_selectedAdPlatform", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "_automatedImportSettings", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "_isAutomatedImportEdit", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "_selectableCampaigns", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "_selectedCampaign", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "fetchBudgets", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "fetchAutomatedImportSettings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "fetchCampaigns", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "saveAutomatedImportSettings", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openDetailDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openDetailDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_toggleAutomatedImport", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_toggleAutomatedImport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_openAutomatedImportDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_openAutomatedImportDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_selectAdPlatform", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_selectAdPlatform"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_removeExcludedCampaign", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_removeExcludedCampaign"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_addExcludedCampaign", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_addExcludedCampaign"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_saveAutomatedImportSettings", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_saveAutomatedImportSettings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCloseAutomatedImportSettings", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseAutomatedImportSettings"), _class.prototype), _class);
});